import React from 'react';

const Home = () => {
  return (
    <div className='text-center mt-20'>
      <h1 className='text-6xl font-bold'>Skyscraper</h1>
      <h2 className='text-xl font-semibold mt-5'>Helping real estate startups find product-market fit</h2>
    </div>
  )
}

export default Home
