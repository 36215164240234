import logo from './logo.svg';
import './App.css';
import Home from './home.js'

function App() {
  return (
    <Home/>
  );
}

export default App;
